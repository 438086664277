<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h1 t-data="page-headline"  class="headline">Users</h1>
      </v-col>
    </v-row>
    <v-diglog-update
      :show="isUpdate"
      :status="action.status"
      :close="closePopup"
      :confirm="confirmUpdate"
    />
    <v-diglog-confirm
      :show="isDelete"
      :subTitle="deleteSubTitle"
      :close="closeDeletePopup"
      :buttonText="deleteButtonConfirmText"
      :confirm="confirmDelete"
    />
    <v-diglog-confirm
      :show="isResetPassword"
      :subTitle="resetPasswordSubTitle"
      :close="closeResetPasswordPopup"
      :buttonText="resetPasswordButtonConfirmText"
      :confirm="confirmResetPassword"
    />
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar v-model="isCreateFail" :content="snackbarFail" type="error"></snack-bar>

    <v-row large v-if="hasPermCreate()">
      <v-col>
        <v-btn large color="primary" :to="{name: 'user-role-create'}" t-data="create-btn"
          ><v-icon left>mdi-plus</v-icon>Add User</v-btn
        >
      </v-col>
    </v-row>

    <!-- Search and Filter -->
    <v-row>
      <v-col cols="3" t-data="status-panel">
        <v-select
          v-model="filters.status"
          @input="selectStatus"
          :items="filters.dropdownStatus"
          label="Status"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col t-data="search-panel">
        <v-text-field
          @input="textSearch"
          v-model="searchPhase"
          class="seachBox"
          append-icon="search"
          label="Search by Email, Name, Org"
          single-line
          hide-details
          @click:clear="clearSearch()"
          clearable
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Bulk action -->
    <v-row v-if="hasPermEdit()">
      <v-col cols="3" t-data="update-status-panel">
        <v-select
          v-model="action.status"
          :items="action.dropdownActions"
          label="Force Update Status"
          @input="setUpdateStatus"
          outlined
        ></v-select>
      </v-col>
      <v-col t-data="bulk-update-btn">
        <v-btn
          large
          color="primary"
          :disabled="isBulkUpdate"
          @click="updateStatusID"
          >Update</v-btn
        >
      </v-col>
    </v-row>

    <!-- Data table -->
    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          v-model="selected"
          :loading="isLoading"
          :options.sync="options"
          :headers="table.headers"
          :items="usersList"
          :items-per-page="table.pageSize"
          :server-items-length="totalUsers"
          :footer-props="{'items-per-page-options': table.itemPerPages}"
          @input="selectId"
          show-select
          item-key="id"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
        <template v-slot:item.is_active="{item}">
          <v-chip :color="statusColor(item.is_active)" dark>{{
            item.is_active ? 'Active' : 'Inactive'
          }}</v-chip>
        </template>
        <template v-slot:item.org__name="{item}">{{ item.org_name }}</template>
        <template v-slot:item.first_name="{item}">{{
          `${item.first_name} ${item.last_name}`
        }}</template>
        <template v-slot:item.updated_at="{item}">{{
          dateFmt(item.updated_at)
        }}</template>
        <template v-slot:item.action="{item}">
          <div style="width: 130px">
            <v-icon
              t-data="edit-action"
              small
              class="px-2"
              v-if="hasPermEdit()"
              @click="rowClick(item.id)"
              >edit</v-icon
            >
            <v-icon t-data="restore-action" small class="px-2" @click="showResetPasswordPopup(item.id)"
              >restore</v-icon
            >
            <v-icon
              t-data="delete-action"
              small
              class="px-2"
              v-if="hasPermDelete()"
              @click="showDeletePopup(item.id)"
              >delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
  convertDate,
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent
} from '../../helper/helper'
import Popup from './UpdateStatusPopup'
import Confirm from '../../components/AlertConfirm'
import SnackBar from '@/components/SnackBar'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    'v-diglog-update': Popup,
    'v-diglog-confirm': Confirm,
    SnackBar
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('userRole', [
      'isDelete',
      'isResetPassword',
      'isPreview',
      'usersList',
      'isLoading',
      'totalUsers',
      'updateIdList',
      'userObject'
    ]),
    isBulkUpdate() {
      return this.updateIdList.length == 0 || this.action.status === ''
    }
  },

  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    hasPermCreate() {
      return hasPermision('CanAddUser')
    },
    hasPermEdit() {
      return hasPermision('CanEditUser')
    },
    hasPermDelete() {
      return hasPermision('CanDeleteUser')
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    async confirmDelete() {
      const isSuccess = await this.deleteUser()
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    confirmResetPassword() {
      this.resetPasswordUser()
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    closeResetPasswordPopup() {
      this.setResetPasswordPopup(false)
    },
    showResetPasswordPopup(id) {
      this.setResetPasswordId(id)
      this.setResetPasswordPopup(true)
    },
    selectId(event) {
      this.selected = event
      this.setSelectedId(event)
    },
    statusColor(status) {
      return status ? 'green' : 'red'
    },
    setUpdateStatus(event) {
      this.action.status = event
    },
    updateStatusID() {
      if (this.selected.length > 0) this.isUpdate = true
    },
    async confirmUpdate() {
      this.isUpdate = false
      const isSuccess = await this.update(this.action.status)
      if (isSuccess) {
        this.selected = []
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    closePopup() {
      this.isUpdate = false
    },
    dateFmt: date => convertDate(date),
    selectStatus() {
      this.setFilterStatus(this.filters.status)
      this.fetch()
    },
    tickPromotion() {
      this.setOnlyPromotion(this.filters.onlyPromotion)
      this.fetch()
    },
    textSearch() {
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.searchPhase.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    rowClick(id) {
      this.$router.push({name: 'user-role-edit', params: {id}})
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    ...mapActions('userRole', [
      'fetch',
      'setPage',
      'setPageSize',
      'setFilterStatus',
      'setTextSearch',
      'setFieldSort',
      'setSort',
      'setSelectedId',
      'update',
      'deleteUser',
      'resetPasswordUser',
      'setDeletePopup',
      'setDeleteId',
      'setResetPasswordPopup',
      'setResetPasswordId',
      'resetList'
    ])
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  data() {
    return {
      pageName: 'user',
      heightOptions: {
        header: 488,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      deleteButtonConfirmText: 'Delete',
      resetPasswordButtonConfirmText: 'Reset',
      deleteSubTitle:
        'If you remove this user. User will now allowed to access portal and disappear from list. Are you sure you want to remove?',
      resetPasswordSubTitle: `If you reset user's password, the user will not be access portal with current password and the system will send the temporary password to user. Are you sure you want to reset?`,
      deleteId: 0,
      searchPhase: '',
      filters: {
        status: 'All',
        onlyPromotion: false,
        dropdownStatus: [
          {text: 'All Status', value: ''},
          {text: 'Active', value: true},
          {text: 'Inactive', value: false}
        ]
      },
      isUpdate: false,
      selected: [],
      options: {},
      action: {
        status: '',
        dropdownActions: ['Active', 'Inactive']
      },
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'No',
            align: 'left',
            sortable: true,
            value: 'id'
          },
          {
            text: 'Email',
            align: 'left',
            value: 'email',
            sortable: true
          },
          {
            text: 'Name',
            align: 'center',
            value: 'first_name',
            sortable: true
          },
          {
            text: 'Org',
            align: 'center',
            value: 'org__name',
            sortable: true
          },
          {
            text: 'Updated',
            align: 'center',
            value: 'updated_at',
            sortable: true
          },
          {
            text: 'Role',
            align: 'left',
            value: 'role_count',
            sortable: false
          },
          {
            text: 'Status',
            align: 'center',
            value: 'is_active',
            sortable: true
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'action',
            sortable: false
          }
        ]
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/'
        },
        {
          text: 'Role & Permission',
          disabled: true,
          link: true,
          to: '/'
        },
        {
          text: 'User',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.page = 1
      },
      deep: true
    }
  }
}
</script>
